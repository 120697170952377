<template>
    <div class="con_cls">
        <div class="nbtn_cls">
            <div class="nav_btn nav_btn_cls1" @click="clkNav(1)">游戏首页</div>
            <div class="nav_btn nav_btn_cls2" @click="clkNav(2)">快速进入</div>
            <div class="nav_btn nav_btn_cls3" @click="clkNav(3)">官方服务</div>
            <div class="nav_btn nav_btn_cls4" @click="clkNav(4)">充值</div>
        </div>
        <div class="logo_cls">
            <img src="@/assets/img_logo.png">
        </div>
    </div>
</template>
<script>
import Utils from '@/js/utils/Utils'
export default {
    name:'NavbarComp',
    setup() {},
    methods:{
        clkNav(type){
            if(type == 1){
                this.$emit('navSelect',type)
            }
            else if(type == 2){
                let url = Utils.getLinkUrl(0)
                window.location.href = url
            }
            else if(type == 3){
                this.$emit('navSelect',type)
            }
            else if(type == 4){
                this.$emit('navSelect',type)
            }
        }
    }
}
</script>
<style scoped>
.con_cls {
    width: 100%;
    height: 66px;
    min-width: 978px;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(18, 116, 175, 0.50);
    box-shadow: 0px -1px 0px 0px #00FFF0 inset;
    z-index: 100;
}
.logo_cls {
    float: left;
    text-align: left;
    margin-left: 40px;
    margin-top: -60px;
    width: 200px;
}
.logo_cls img {
    width: 100%;
}
.nbtn_cls {
    display: flex;
    width: 96%;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: right;
}
.nav_btn {
    width: 180px;
    color: #00FFF0;
    font-size: 30px;
    font-weight: 800;
    cursor: pointer;
}
.nav_btn:hover {
    text-shadow: 0 0 1px #fff;
}
.nav_btn_cls1 {
    text-shadow: 0px 2px 8px #002A44;
}
.nav_btn_cls2 {
    color: #FFF500;
    background: linear-gradient(142deg, #FFF500 29.3%, #F59C1B 78.21%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border-left: 2px #002A44 solid;
    border-right: 2px #002A44 solid;
}
.nav_btn_cls3 {
    color: #00FFF0;
    text-shadow: 0px 1px 4px #002A44;
    border-right: 2px #002A44 solid;
}
.nav_btn_cls4 {
    color: #00FFF0;
    text-shadow: 0px 1px 4px #002A44;
}
</style>