<template>
  <div class="home_cls">
    <NavbarComp v-if="!mobileFlag" @navSelect="selectHandler"></NavbarComp>
    <div class="upbg_cls">
      <img :class="mobileFlag ? 'logo_cls1' : 'logo_cls2'" src="@/assets/img_kouhao.png">
    </div>
    <div v-if="!showKefu" class="midbg_cls">
      <img class="midbg_img_cls" src="@/assets/description_1.png">
      <img class="midbg_img_cls" src="@/assets/description_2.png">
      <img class="midbg_img_cls" src="@/assets/description_3.png">
      <img class="midbg_img_cls" src="@/assets/description_4.png">
    </div>
    <div v-else>
      <div class="lgcode_con_cls">
        <div :class="mobileFlag ? 'lgcode_cls1' : 'lgcode_cls2'">
          <img src="/images/img_pubcode.png">
          <p>公众号:bylm6677</p>
        </div>
        <div :class="mobileFlag ? 'lgcode_cls1' : 'lgcode_cls2'">
          <img src="/images/img_kefucode.png">
          <p>客服:bylm677</p>
        </div>
      </div>
    </div>
    <a class="download_cls download_t_cls" v-if="mobileFlag" href="javascript:void(0)" @click="clickDownload()">
      <img src="@/assets/button_2.png">
    </a>
    <a class="download_cls start_t_cls" href="javascript:void(0)" @click="clickStart()">
      <img src="@/assets/button_1.png">
    </a>
    <template v-if="!mobileFlag">
      <QRCodeComp></QRCodeComp>
    </template>
    <template v-else>
      <div class="left_btn_cls">
        <img src="@/assets/btn_kefu.png" @click="showKefuView()">
        <img src="@/assets/btn_charge.png" @click="chargeFlag = true">
      </div>
    </template>
    <KefuComp v-if="showQRCodeFlag" @showKefuEvent="showKefuView()"></KefuComp>
    <template v-if="chargeFlag">
      <ChargeComp @closeEvent="chargeFlag = false"></ChargeComp>
    </template>
    <template v-if="isWXBwer">
      <div class="tip_cls">
        <img class="tip_guide" src="@/assets/img_jiantou.png">
        <div class="tip_text">
          <p class="tip_p1">微信无法下载</p>
          <p class="tip_p2">请点击<span>右上角按钮</span>〖•••〗</p>
          <p class="tip_p3">选择〖<span>在浏览器中打开</span>〗</p>
          <p class="tip_p4">即可正常下载</p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Utils from '../js/utils/Utils'
import NavbarComp from './NavbarComp.vue'
import QRCodeComp from './QRCodeComp.vue'
import KefuComp from './KefuComp.vue'
import ChargeComp from './ChargeComp.vue'
import dataCenter from '@/js/DataCenter'
export default {
    name: "HomeComp",
    components: { QRCodeComp,NavbarComp,KefuComp,ChargeComp },
    props: {
        msg: String
    },
    data() {
        return {
            mobileFlag:false,
            showKefu:false,
            showQRCodeFlag:false,
            chargeFlag:false,
            isWXBwer:false
        };
    },
    mounted() {
      this.mobileFlag = Utils.isMobile()
      this.isWXBwer = Utils.isWXBroswer()
    },
    methods: {
        clickDownload() {
            let url = Utils.getDownloadLink();
            window.open(url, "_blank");
            dataCenter.uploadStatistics(Utils.isAndorid() ? 1 : 2)
        },
        clickStart(){
            let url = Utils.getLinkUrl(0)
            window.open(url, "_blank");
            dataCenter.uploadStatistics(3)
        },
        showKefuView(){
          this.showQRCodeFlag = !this.showQRCodeFlag
        },
        selectHandler(type){
          this.showKefu = type == 3
          this.chargeFlag = type == 4
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

.tip_cls {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
}
.tip_guide {
  position: absolute;
  width: 40vw;
  top: 10vw;
  right: 10vw;
}
.tip_text {
  position: absolute;
  width: 80vw;
  height: 33vw;
  top: 42vw;
  left: 10vw;
  font-size: 5vw;
  color: white;
  letter-spacing: 6px;
  border-radius: 33vw;
  border: 1vw #fff dotted;
  padding: 3vw 0 0 0;
  box-sizing: border-box;
}
.tip_p1 {
  font-size: 4.5vw;
}
.tip_p2 {
  font-weight:bold;
}
.tip_p3 {
  font-weight:bold;
}
.tip_p4 {
  font-size: 4.5vw;
}
.tip_p2 span {
  color: red;
}
.tip_p3 span {
  color: red;
}
.home_cls {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.upbg_cls {
  height: 80vh;
  overflow: hidden;
  background: url("@/assets/imgubg.jpg") no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: bottom;
}
.logo_cls1 {
  width: 80vw;
  position: relative;
  top: 10vh;
  margin: 0 auto;
}
.logo_cls2 {
  width: 80vw;
  max-width: 600px;
  position: relative;
  top: 15vh;
  margin: 0 auto;
}
.midbg_cls {
  width: 100vw;
  display: flex;
  margin-bottom: 5vh;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.midbg_img_cls {
  width: 100%;
  max-width: 800px;
  display: block;
  padding: 0 20px;
  box-sizing: border-box;
}
.download_cls {
  display: block;
  width: 100%;
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  z-index: 10;
  cursor: pointer;
}
.download_t_cls {
  top: 47vh;
}
.start_t_cls {
  top: 60vh;
}
.download_cls img {
  width: 50vw;
  max-width: 400px;
}
.left_btn_cls {
  width: 14vw;
  position: fixed;
  right: 0;
  top: 3vh;
}
.left_btn_cls img {
  display: block;
  margin-top: 10px;
  width: 100%;
}
.lgcode_con_cls{
  widows: 80%;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 4vh;
  display: flex;
  padding: 20px 0;
  flex-direction: row;
  justify-content: space-around;
}
.lgcode_cls1 {
  width: 40%;
  color: #FFC875;
  font-size: 4vw;
}
.lgcode_cls2 {
  width: 40%;
  max-width: 132px;
  color: #FFC875;
  font-size: 17px;
}
.lgcode_con_cls img {
  width: 100%;
}
</style>
